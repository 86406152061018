import React, { useState } from 'react';

import Headers from './Header';
import Filters from './Filter';
import GeneralInformation from './GeneralCard';
import Applicants from './Gender';
import Sector from './Sector';
import Organisations from './Organisations';
import Regions from './Regions';
import { useProject } from '../../hooks/useProject';
import useGetAllpplicant from '../../api/Reports/useGetAllAplicants';
import AsnSpin from '../../components/Forms/Spin';
import { defaultDateRange } from '../../helpers/constants';
import { IfilterDataAllReport } from '../../types/report';

const Reports: React.FC = () => {
  const { projectId } = useProject();
  const [selectedFilters, setSelectedFilters] = useState<IfilterDataAllReport>(
    {} as any
  );

  const AgeInterval = (() => {
    if (
      selectedFilters.age !== null &&
      typeof selectedFilters.age === 'string'
    ) {
      return selectedFilters.age.split('-');
    }
    return [];
  })();

  const { data: dataApplicant, isLoading } = useGetAllpplicant(
    projectId as string,
    {
      ...(selectedFilters?.statuses?.length === 0 ? {} : { statuses: selectedFilters?.statuses }),
      ...(selectedFilters?.organization?.length === 0 ? {} : { organization: selectedFilters?.organization }),
      ...(selectedFilters?.regions?.length === 0 ? {} : { regions: selectedFilters?.regions }),
      ...(selectedFilters?.sectors?.length === 0 ? {} : { sectors: selectedFilters?.sectors }),
      ...(selectedFilters?.gender === undefined ? {} : { gender: selectedFilters?.gender }),
      ...(selectedFilters?.disability === undefined ? {} : { disability: selectedFilters?.disability }),
      ...(selectedFilters?.retrained !== null && { retrained: selectedFilters?.retrained }),
      age:
        AgeInterval?.length !== 0
          ? {
              from: +AgeInterval[0],
              to: +AgeInterval[1]
            }
          : undefined,
      date:
        Array.isArray(selectedFilters.date) && selectedFilters.date.length === 2
          ? {
              from: selectedFilters.date[0],
              to: selectedFilters.date[1]
            }
          : defaultDateRange
    },
    { enabled: Boolean(projectId) }
  );

  if (isLoading) {
    return <AsnSpin />;
  }
  return (
    <div style={{ padding: 40 }}>
      <Headers dataExport={selectedFilters as never} />
      <Filters
        setSelectedFilters={setSelectedFilters as never}
        selectedFilters={selectedFilters as never}
      />
      <GeneralInformation data={dataApplicant} />
      <div style={{ display: 'flex', gap: 20, padding: '40px 0' }}>
        <Applicants dataGender={dataApplicant} />
        <Sector dataSector={dataApplicant} />
      </div>
      <div style={{ display: 'flex', gap: '0px 20px' }}>
        <Organisations dataOrganisations={dataApplicant} />
      </div>
      <div style={{ margin: '20px 0px' }}>
        <Regions dataRegions={dataApplicant} />
      </div>
    </div>
  );
};
export default Reports;
