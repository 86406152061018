import React from 'react';
import { Pie, PieConfig } from '@ant-design/charts';
import { CardContainer, ChartTitleContainer } from '../../components/Dashboard/dashboardStyle';
import { IApplicnts } from '../../types/report';

const Applicants: React.FC<IApplicnts> = ({ dataGender }) => {
  const data = [
    { label: 'Female', value: +dataGender?.result?.femaleApplicants },
    { label: 'Male', value: +dataGender?.result?.maleApplicants }
  ];

  const color: (d: any, defaultColor?: string | undefined) => string = (d, defaultColor) => {
    if (d.label === 'Male') {
      return '#688EA3';
    } else if (d.label === 'Female') {
      return '#F3C262';
    } else {
      return '#F3C262';
    }
  };

  const config: PieConfig = {
    data,
    angleField: 'value',
    colorField: 'label',
    radius: 1,
    innerRadius: 0.7,
    appendPadding: 10,
    padding: [0, 0, 75, 20],
    renderer: 'svg',
    color,
    legend: {
      position: 'left-bottom',
      layout: 'vertical',
      offsetY: -70,
      itemWidth: 600
    },
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14
      }
    },
    interactions: [
      {
        type: 'element-selected'
      },
      {
        type: 'element-active'
      }
    ],
    statistic: {
      title: {
        style: {
          color: '#263238',
          fontSize: '20px',
          lineHeight: '20px',
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        content: ''
      }
    }
  };

  return (
    <CardContainer width={'clamp(250px, 35vw, 100%)'}>
      <ChartTitleContainer>Applicants by gender</ChartTitleContainer>
      <Pie {...config} />
    </CardContainer>
  );
};

export default Applicants;
